<template lang="pug">
  v-container
    v-row
      v-col.pb-0(cols="12")
        //- v-toolbar(
        //-   color="primary"
        //-   elevation="0"
        //-   dark
        //- )
        //-   span New Customer
        //-   v-spacer
        //-   v-icon mdi-account
        //-   v-icon mdi-plus
        v-alert(
          class="mt-2"
          type="success"
          dismissible
          v-for="(item, index) in custPostSuccess" :key="index"
        )
          span.text-uppercase {{ item }}
        v-alert(
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in custPostErrors"
          :key="index"
        )
          span.text-uppercase {{ item.first() }}
      v-col.pb-0(
        lg="4"
        offset-lg="4"
        cols="12"
      )
        v-text-field(
          label="TIN"
          outlined
          dense
          v-model="tinNumber"
        )
      v-col.pb-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          label="Customer ID"
          outlined
          dense
          readonly
          :value="customerId"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-autocomplete(
          label="Customer Category"
          outlined
          dense
          v-model="customerCategory"
          :items="custCatList"
          :loading="custCatGetting"
          :filter="autocompleteFilter(['id', 'name'])"
          item-value="id"
          return-object
        )
          template(v-slot:selection="{ item }")
            span.primary--text {{ item.id }}
            span.blue-grey--text.mx-1.text-caption {{ item.name }}
          template(v-slot:item="{ item }")
            span.primary--text {{ item.id }}
            span.blue-grey--text.mx-1.text-caption {{ item.name }}
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="Company"
          v-model="company"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="Region"
          v-model="region"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="Province"
          v-model="province"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="City"
          v-model="city"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="Brgy"
          v-model="brgy"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="Purok"
          v-model="purok"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="Street"
          v-model="street"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="House No"
          v-model="houseNo"
        )
      v-col.py-0(
        lg="4"
        cols="12"
      )
        v-text-field(
          outlined
          dense
          label="Contact No"
          v-model="contactNo"
        )
      v-col(
        lg="1"
        cols="12"
      )
        v-btn(
          color="primary"
          dark
          @click="storeCustomer"
          :loading="custPosting || custCatGetting"
        )
          span Save
      v-col(
        lg="1"
        cols="12"
      )
        v-btn(
          color="red"
          dark
          @click="$router.push('/customers')"
        )
          span Cancel
</template>
<script>
import custCatRepository from '@/repositories/customer-category.repository'
import customerRepository from '@/repositories/customer.repository'
import { getVars, postVars, errorHandler } from '@/libs/api-helper.extra'

const inputVars = () => ({
  customerId: null,
  tinNumber: null,
  customerCategory: {},
  company: null,
  region: null,
  province: null,
  city: null,
  brgy: null,
  purok: null,
  street: null,
  houseNo: null,
  contactNo: null,
})

export default {
  name: 'CreateCustomer',
  created () {
    this.getCustCat()
    this.websocketEvents()
  },
  data () {
    return {
      ...inputVars(),
      ...getVars('cust-cat'),
      ...postVars('cust', { success: [] }),
    }
  },
  watch: {
    customerCategory (item) {
      if (!item.id) return
      const customer = item.latest_customer || {}
      this.customerId = this.$genKeyIdCategory(customer.id, item.id)
    },
  },
  methods: {
    getCustCat () {
      if (this.custCatGetting) return
      this.custCatGetting = true
      custCatRepository.index()
        .then(({ data }) => { this.custCatList = data })
        .catch(e => { this.custCatGetErrors = errorHandler(e) })
        .then(() => { this.custCatGetting = false })
    },
    websocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.execPayloadStoreEvent)
        // .listen('CustomerUpdatedEvent', e => { this.getCustCat() })
        // .listen('CustomerCategoryUpdatedEvent', e => { this.getCustCat() })
    },
    async execPayloadStoreEvent ({ model, data }) {
      if (!model || !this.$objectEmpty(data)) return
      if (model === 'Customer') {
        this.processCustomerId(data)
        return
      }
      if (model === 'CustomerCategory') {
        const custCatList = Object.assign({ ...this.custCatList })
        this.custCatList = custCatList.objectInsertion(data, 'id', 'id')
      }
    },
    processCustomerId (data) {
      if (!data) return
      this.customerId = this.$genKeyIdCategory(data.id, data.customer_category_id)
    },
    storeCustomer () {
      if (this.custPosting || this.custCatGetting) return
      this.custPosting = true
      this.custPostErrors = []
      this.custPostSuccess = []
      const data = this.getInputData()
      customerRepository.store(data)
        .then(this.storeSuccess())
        .catch(e => { this.custPostErrors = errorHandler(e) })
        .then(() => { this.custPosting = false })
    },
    storeSuccess () {
      return ({ data }) => {
        this.custPostSuccess = [`${data.id} stored successfully`]
        this.getCustCat()
        this.reset(inputVars())
      }
    },
    reset (vars) {
      const excluded = ['region', 'province', 'city']
      Object.keys(vars)
        .filter(key => !excluded.includes(key))
        .forEach(e => { this[e] = vars[e] })
    },
    getInputData () {
      return {
        id: this.customerId,
        customer_category_id: (this.customerCategory.id || null),
        company: this.company,
        region: this.region,
        province: this.province,
        tin_number: this.tinNumber,
        city: this.city,
        brgy: this.brgy,
        purok: this.purok,
        street: this.street,
        house_no: this.houseNo,
        contact_no: this.contactNo,
      }
    },
    autocompleteFilter (fields) {
      return (item, queryText, itemText) => {
        const filters = fields.map(e => item[e])
        const rxItem = new RegExp(filters.join(), 'i')
        const rxQueryText = new RegExp(queryText, 'i')
        return rxQueryText.test(rxItem)
      }
    },
  },
}
</script>
